import React, {Fragment} from 'react';
import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {TextResource} from '../infrastructure/TextResource';
import {ScreenSizeComponent} from '../infrastructure/media-type';
import {Result} from '../infrastructure/api-result';
import {ErrorMessages} from '../infrastructure/errors';
import {PatientLabResultsResponse, AdDto} from '../dto';
import {PdfDocument} from '../infrastructure/components/PdfDocument';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {downloadFile, delay, base64ToArray, printPdf} from '../infrastructure/util';

import './ViewLabResults.scss';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {texts} from '../infrastructure/texts';
import {RouteComponentProps} from 'react-router-dom';
import SessionComponent from '../infrastructure/components/SessionComponent';

interface RouterParams {
  uniqueRecordPrefix: string;
}

interface Props extends RouteComponentProps<RouterParams>, AppContextProps {
}

interface State {
  itemsLoading: boolean;
  items: PatientLabResultsResponse | undefined;

  documentLoading: boolean;
  documentContent: string | undefined;
  documentTitle: string | undefined;
  documentLoadResult: Result | undefined;
  documentUniquePrefix: string | undefined;
  documentLanguageType: number | undefined;

  isInDebt: boolean;

  initialLoading: boolean;

  documentBytes: Uint8Array | undefined;

  ad: AdDto | undefined;

  hasEnglishDocument: boolean;

  loadingAiReading: boolean;
  aiReadingError: string[] | undefined;
}

export class ViewLabResults extends BaseComponent<Props, State> {

  state: State = {
    itemsLoading: false,
    items: undefined,

    documentLoading: false,
    documentContent: undefined,
    documentTitle: undefined,
    documentLoadResult: undefined,
    documentUniquePrefix: undefined,
    documentLanguageType: undefined,

    isInDebt: false,
    initialLoading: true,
    documentBytes: undefined,
    ad: undefined,
    hasEnglishDocument: false,

    loadingAiReading: false,
    aiReadingError: undefined,
  };

  async fetchLabResultList() {

    const {server, actions} = this.props.context;

    await this.setStateAsync({itemsLoading: true});

    const response = await server.patientLabResults({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({
        itemsLoading: false,
        items: undefined,
        initialLoading: false,
      });
      return;
    }

    await this.setStateAsync({
      itemsLoading: false,
      items: response.payload,
      isInDebt: response.payload.isInDebt,
      initialLoading: false,
    });
  }

  async fetchPdfResult(uniqueRecordPrefix: string, documentLanguageType: number) {
    const {server, actions} = this.props.context;

    await this.setStateAsync({
      documentLoading: true,
      documentContent: undefined,
      documentBytes: undefined,
    });

    const response = await server.patientResult({uniqueRecordPrefix, documentLanguageType});

    if (!response.success) {

      actions.errors.setErrorMessages(response.errorMessages);

      await this.setStateAsync({
        documentLoading: false,
        documentContent: undefined,
        documentBytes: undefined,
        initialLoading: false,
      });

      return;
    }

    if (response.payload.isInDebt) {
      await this.setStateAsync({
        documentLoading: false,
        documentContent: undefined,
        documentBytes: undefined,
        documentTitle: undefined,
        documentLoadResult: undefined,
        isInDebt: response.payload.isInDebt,
        initialLoading: false,
      });
    } else {
      await this.setStateAsync({
        documentContent: response.payload.pdfContent,
        documentBytes: base64ToArray(response.payload.pdfContent as string),
        documentTitle: response.payload.title,
        documentLoadResult: undefined,
        isInDebt: response.payload.isInDebt,
        initialLoading: false,
      });
    }
  }

  async fetchAd(uniqueRecordPrefix: string | undefined) {

    const {server, actions} = this.props.context;

    if (!uniqueRecordPrefix) {
      return;
    }

    const response = await server.getAd({uniqueRecordPrefix, position: 2});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      return;
    }

    if (response.payload.item) {
      await this.setStateAsync({ad: response.payload.item});
      const displayResponse = await server.markAdAsDisplayed({
        uniqueRecordPrefix,
        acodeID: response.payload.item.acodeID,
      });

      if (!displayResponse.success) {
        actions.errors.setErrorMessages(displayResponse.errorMessages);
        return;
      }
    }
  }

  async adClicked(_: any, ad: AdDto, uniqueRecordPrefix: string) {

    const {server, actions} = this.props.context;

    const clickedResponse = await server.markAdAsClicked({
      uniqueRecordPrefix,
      acodeID: ad.acodeID,
    });

    if (!clickedResponse.success) {
      actions.errors.setErrorMessages(clickedResponse.errorMessages);
      return;
    }
  }

  async fetchData(uniqueRecordPrefix: string | undefined, documentLanguageType: number) {

    const listPromise = this.fetchLabResultList();

    const documentPromise = uniqueRecordPrefix
      ? await this.fetchPdfResult(uniqueRecordPrefix, documentLanguageType)
      : undefined;

    const adPromise = this.fetchAd(uniqueRecordPrefix);

    await Promise.all([listPromise, documentPromise, adPromise]);
  }

  async componentDidMountAsync(): Promise<void> {
    await this.setStateAsync({documentUniquePrefix: this.props.match.params.uniqueRecordPrefix});
    if (!this.state.documentUniquePrefix) {
      await this.setStateAsync({
        documentLoading: false,
        documentContent: undefined,
        documentTitle: undefined,
        documentLoadResult: undefined,
        initialLoading: false,
      });
    }

    await this.fetchData(this.state.documentUniquePrefix, 1);
  }

  downloadPdf = () => {

    if (!this.state.documentContent) {
      throw new Error('Trying to print while documentContent is falsy.');
    }

    downloadFile(
      this.state.documentBytes,
      this.state.documentContent,
      `${this.state.documentTitle}.pdf`,
    );
  };

  printPdf = () => {
    if (!this.state.documentContent) {
      throw new Error('Trying to print with falsy documentContent.');
    }

    printPdf(
      this.state.documentBytes,
      this.state.documentContent,
      `${this.state.documentTitle}.pdf`,
    );
  };

  onPdfLoad = async (pdfLoadResult: Result) => {
    await delay(0);

    await this.setStateAsync({
      documentLoadResult: pdfLoadResult,
      documentLoading: false,
      aiReadingError: undefined,
    });
  };

  loadDocument = async () => {
    await this.fetchData(this.state.documentUniquePrefix, this.state.documentLanguageType ?? 1);
    window.scrollTo(0, 0);
  };

  sendDocumentForAiReading = async () => {
    if (!this.state.documentUniquePrefix) {
      return;
    }

    await this.setStateAsync({loadingAiReading: true, aiReadingError: undefined});

    const {server} = this.props.context;

    const response = await server.aiResultReading({uniqueRecordPrefix: this.state.documentUniquePrefix});
    if (!response.success) {
      await this.setStateAsync({loadingAiReading: false, aiReadingError: response.errorMessages});
      return;
    }

    await this.setStateAsync({loadingAiReading: false});
    window.location.href = response.payload.urlToRedirect;
    // window.open(response.payload.urlToRedirect, '_blank');
  };

  render() {

    const {
      items,
      documentContent,
      documentLoading,
      documentTitle,
      documentLoadResult,
      isInDebt,
      initialLoading,
      documentBytes,
      ad,
      documentUniquePrefix,
      hasEnglishDocument,
      aiReadingError,
      loadingAiReading,
    } = this.state;

    return (
      <div className="patient-lab-results-page">

        <DocumentTitle title="Резултати"/>

        <div className="row" style={{margin: '0'}}>

          <div className="col-xl-8">

            {isInDebt && <div className="card-body padded alert alert-warning">
                <TextResource injectHtml resourceId={20}/>
            </div>
            }
            {!isInDebt && items && !documentUniquePrefix &&
                <TextResource injectHtml resourceId={2}/>}

            {!isInDebt && items && !items.items.length &&
                <div className="card-body text-center padded alert alert-primary">
                    <h5>Към момента нямате готови резултати за визуализиране.</h5>
                </div>
            }

            {!isInDebt && !!documentContent && !!documentBytes && <Fragment>

                <div className="card resource-box">
                    <div className="card-body">
                        <div>
                            <h4 className="text-center document-title">{documentTitle}</h4>
                        </div>

                        <div className="button-group">
                          {documentLoadResult && documentLoadResult.success && <Fragment>
                              <hr/>

                              <div className="text-center">
                                  <SessionComponent feature="AiResultReadingPage">
                                      <button
                                          type="button"
                                          className="btn btn-md btn-blue"
                                          disabled={loadingAiReading}
                                          onClick={this.sendDocumentForAiReading}>
                                          AI разчитане
                                      </button>
                                  </SessionComponent>

                                  <button className="btn btn-md btn-success"
                                          onClick={this.downloadPdf}>
                                    {texts.PATIENT_LABRESULT_DOWNLOAD}
                                  </button>

                                  <ScreenSizeComponent size="not-phone">
                                      <button className="btn btn-md btn-default"
                                              onClick={this.printPdf}>
                                        {texts.PATIENT_LABRESULT_PRINT}
                                      </button>
                                  </ScreenSizeComponent>

                                {hasEnglishDocument &&
                                    <button className="btn btn-md btn-info"
                                            onClick={async () => {
                                              await this.setStateAsync({
                                                documentUniquePrefix,
                                                documentLanguageType: 2,
                                                hasEnglishDocument,
                                              });
                                              await this.loadDocument();
                                            }}>
                                        ENGLISH
                                    </button>
                                }
                              </div>
                            {aiReadingError && <ErrorMessages errors={aiReadingError}/>}
                              <SessionComponent feature="AiResultReadingPage">
                                  <div style={{marginTop: '10px'}}><strong>Възползвайте се от иновативното AI разчитане!</strong></div>
                              </SessionComponent>
                          </Fragment>}
                        </div>
                    </div>
                </div>

              {!!ad && <div className="card resource-box">
                  <div className="card-body">
                      <a
                          href={ad.banerUrl}
                          onClick={(e) => this.adClicked(e, ad, '')}
                          target="_blank">
                          <img src={ad.imageBase64} width="100%"/>
                      </a>
                  </div>
              </div>}

              {!!documentLoadResult && !documentLoadResult.success &&
                  <ErrorMessages errors={documentLoadResult.errorMessages}/>}

                <PdfDocument data={documentBytes} onLoad={this.onPdfLoad}/>

            </Fragment>}

            {(initialLoading || documentLoading) && <LoadingIndicator className="document-loading" delay={0}/>}
          </div>

          <div className="col-xl-4">

            {items && !isInDebt && !!items.items.length && <Fragment>

                <div className="card resource-box">
                    <div className="card-body padded">
                      {texts.PATIENT_LABRESULT_EXAMS}
                    </div>
                </div>

                <div className="card result-list-box">
                    <div className="card-body">
                      {items && items.items.map((x) =>
                        <div key={x.uniqueRecordPrefix}
                             className={`list-group-item active result-item-link ${(!x.hasPatientRecord ? 'not-ready' : '')}`}>

                          <div
                            className={`pr ${(x.hasEnglishDocument ? 'padding-right-76' : '')}`}
                            onClick={async () => {
                              if (!x.hasPatientRecord) {
                                return;
                              }
                              await this.setStateAsync({
                                documentUniquePrefix: x.uniqueRecordPrefix,
                                documentLanguageType: 1,
                                hasEnglishDocument: x.hasEnglishDocument,
                              });
                              await this.loadDocument();
                            }}>
                            {x.onlineDisplayName}
                          </div>

                          {x.hasEnglishDocument &&
                              <button type="button"
                                      className="btn btn-sm btn-info"
                                      style={{position: 'absolute', top: 0, right: 0, margin: '-1px -1px 0 0'}}
                                      onClick={async () => {
                                        await this.setStateAsync({
                                          documentUniquePrefix: x.uniqueRecordPrefix,
                                          documentLanguageType: 2,
                                          hasEnglishDocument: x.hasEnglishDocument,
                                        });
                                        await this.loadDocument();
                                      }}>
                                  English
                              </button>
                          }
                        </div>)}
                    </div>
                </div>
            </Fragment>}
          </div>
        </div>
      </div>
    );
  }
}
